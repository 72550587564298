import React, {useEffect} from "react";
import Layout from '../components/Layout';

import tw from 'twin.macro';
import Seo from '../components/SEO';

const Heading = tw.h1`text-3xl font-bold tracking-tight text-primary-700 dark:text-white`;
const Title = tw.h2`block max-w-2xl mt-4 text-xl text-gray-500 dark:text-gray-300`;

const GetStarted = ({
    heading = "Ready to file 2021 Tax return.",
  title = (
    <>
    Fill out the get started form, <span tw="text-primary-500">CountMyCents </span> representative will help you file your tax return.
    </>),
}) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    region: "na1",
	                portalId: "21292637",
	                formId: "408c1e88-8d4d-41bc-9a80-db0746c8bfe6",
                    target: '#hubspotForm'
                })
            }
        });
    }, []);

    return (
        <Layout>
            <Seo
        title="Get started tax filing form."
        description= "Fill out the get started form, CountMyCents representative will help you file your tax return."
      />
       <section tw="bg-white dark:bg-gray-900 mt-16">
        <div tw="container flex flex-col items-center px-4 py-12 mx-auto text-center">
          <Heading>{heading}</Heading>
          <Title>{title}</Title>
          </div>
          </section>
            <div tw="mt-6 mx-4 lg:mx-32 " id="hubspotForm">{}</div>
           
         
        </Layout>
    );

}

export default GetStarted;